<template>
  <div v-if="club.users">
    <!-- listar usuarios relacionados al club actual -->
    <b-modal
      id="users-list-m"
      :title="club.users.length ? $t('clubs.corporate.updateAdministrator') : $t('clubs.corporate.addAdministrator')"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-table
            class="position-relative"
            :items="users"
            :fields="userFields"
            :no-border-collapse="true"
            responsive
            primary-key="id"
            show-empty
            :empty-text="$t('generic.noRecordsFound')"
          >
            <template #cell(role.is_admin_club)="row">
              <b-form-checkbox
                v-model="row.item.role.is_admin_club"
                :checked="row.item.role.is_admin_club"
                name="check-button"
                switch
                inline
                @change="assignNewRol(row)"
              >
                <span v-text="row.item.role.is_admin_club ? 'Si' : 'No'" />
              </b-form-checkbox>
            </template>
          </b-table>
          <app-paginator
            :data-list="users"
            @pagination-data="assignFiltersOfUsers"
          />
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="i-margin-top-button-m"
                variant="secondary"
                pill
                @click="$bvModal.hide('users-list-m')"
              >
                {{ $t('buttons.cancel') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
    <!--/ listar usuarios relacionados al club actual -->

    <!-- seleccionar roles relacionados al club actual -->
    <b-modal
      id="roles-list-m"
      :title="club.users.length ? $t('clubs.corporate.updateAdministrator') : $t('clubs.corporate.addAdministrator')"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <validation-observer ref="formRules">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label-for="club-name">
                    <template v-slot="label">
                      {{ $t('clubs.corporate.sportsClubName') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="Club"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-input
                            id="club-name"
                            v-model="roleForm.clubName"
                            :state="errors.length > 0 ? false:null"
                            class="form-control"
                            placeholder="Club"
                            disabled
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </template>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-for="role-id">
                    <template v-slot="label">
                      {{ $t('roles.rol') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="administrator"
                        rules="required"
                      >
                        <v-select
                          id="role-id"
                          v-model="roleForm.roleId"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :options="roles"
                          :reduce="option => option.hash"
                          :placeholder="$t('roles.selectRole')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </template>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-for="job-position-id">
                    <template v-slot="label">
                      {{ $t('generic.jobPosition') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="job_position"
                        rules="required"
                      >
                        <v-select
                          id="job-position-id"
                          v-model="roleForm.jobPositionId"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :options="jobPositions"
                          :reduce="option => option.hash"
                          :placeholder="$t('jobPosition.selectJobPosition')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="i-margin-top-button-m"
                variant="secondary"
                pill
                @click="$bvModal.hide('roles-list-m')"
              >
                {{ $t('buttons.cancel') }}
              </b-button>
              <b-button
                variant="primary"
                style="margin-left: 17px;"
                pill
                :style="colorPrimaryBtn"
                @click="changeRole"
              >
                {{ $t('buttons.save') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
    <!--/ seleccionar roles relacionados al club actual -->
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { BModal, BRow, BCol, BButton, BTable, BFormCheckbox } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import clubService from '@/services/clubsService'
import userService from '@/services/userService'
import Ripple from 'vue-ripple-directive'
import AppPaginator from '@/views/components/app-paginator/AppPaginator.vue'
import vSelect from 'vue-select'

export default {
  components: {
    AppPaginator,
    BModal,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  props: {
    club: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Array,
      default: null,
    },
    clubId: {
      type: Number,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      roles: [],
      applyAdmin: false,
      selectedUser: null,
      roleForm: {
        clubName: null,
        roleId: null,
        jobPositionId: null,
      },
      required,
    }
  },

  computed: {
    userFields() {
      return [
        {
          key: 'full_name',
          label: this.$t('generic.name'),
          sortable: true,
        },
        {
          key: 'role.name',
          label: this.$t('roles.rol'),
          sortable: true,
        },
        {
          key: 'role.is_admin_club',
          label: this.$t('clubs.corporate.hasAdministratorRole'),
          sortable: true,
        },
      ]
    },
  },

  methods: {
    assignFiltersOfUsers(pSFilters) {
      // this.societyFilters.page = pSFilters.page
      // this.societyFilters.perPage = pSFilters.perPage
      // this.fetchSocieties()
    },

    assignNewRol(row) {
      this.selectedUser = row.item.hash
      this.applyAdmin = row.item.role.is_admin_club
      const message = row.item.role.is_admin_club
        ? 'Al agregar este usuario como administrador, es necesario asignarle un nuevo rol'
        : 'Al remover este usuario como administrador, es necesario asignarle un nuevo rol'

      this.$swal({
        title: '¿Estas seguro?', // 'Are you sure?',
        text: message, // 'You won't be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, continuar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.fetchRolesByClub()
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'No se han realizado cambios',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          this.users[row.index].role.is_admin_club = !this.users[row.index].role.is_admin_club
          this.applyAdmin = false
        } else {
          this.users[row.index].role.is_admin_club = !this.users[row.index].role.is_admin_club
          this.applyAdmin = false
        }
      })
    },

    fetchRolesByClub() {
      clubService.getRolesByClubId(this.$route.params.id).then(({ data }) => {
        this.fetchJobPositions()

        const rolesData = data.data
        this.roles = this.applyAdmin ? rolesData.filter(item => item.is_admin_club) : rolesData.filter(item => !item.is_admin_club)
        this.roleForm = {
          clubName: this.club.club_name,
          roleId: null,
          jobPositionId: null,
        }
      }).catch(error => {

      })
    },

    fetchJobPositions() {
      clubService.getJobPositionsByClubId(this.$route.params.id).then(({ data }) => {
        this.jobPositions = data.data
        this.$bvModal.hide('users-list-m')
        this.$bvModal.show('roles-list-m')
      }).catch(error => {

      })
    },

    changeRole() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          const dataAux = {
            club_id: this.club.hash,
            role_id: this.roleForm.roleId,
            job_position_id: this.roleForm.jobPositionId,
          }

          userService.updateUserInfo(this.selectedUser, dataAux).then(response => {
            this.$swal({
              icon: 'success',
              title: '¡Éxito!',
              text: 'El usuario se ha modificado correctamente',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })

            this.$bvModal.hide('roles-list-m')
            this.$emit('refresh-info-club', this.clubId)
          }).catch(error => {

          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
