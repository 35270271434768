<template>
  <b-overlay
    :show="applyOverlay"
    :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!applyOverlay">

      <!-- Imagenes del club -->
      <b-col
        sm="12"
        class="mb-2 text-center i-display-elements"
      >
        <!-- cropper + imagen -->
        <cropper
          v-if="croppedImage"
          :src="croppedImage"
          :stencil-props="{ handlers: {}, movable: false, scalable: false, }"
          :stencil-size="{ width: 1200, height: 312 }"
          image-restriction="stencil"
          class="cropper"
          @change="change"
        />
        <b-img
          v-else
          :src="club.cover_image_file"
          alt="cover image"
          class="i-demo-img"
        />
        <!--/ cropper + imagen -->

        <!-- cargar imagen -->
        <p
          v-if="checkPermission(['EDIT_CLUB', 'ALLOW_ALL'])"
          class="float-right i-translate-avatar-cl"
        >
          <span v-if="!croppedImage">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-icon rounded-circle"
              pill
              :style="colorPrimaryBtn"
              variant="primary"
              @click="$refs.refInputCover.click()"
            >
              <i class="icon-0-icons-dark-image text-center" />
              <span class="d-none d-md-inline"> {{ $t('generic.uploadCoverImage') }}</span>
            </b-button>
            <input
              ref="refInputCover"
              type="file"
              class="d-none"
              accept=".jpg, .png, .gif"
              @input="inputImageRenderer"
            >
          </span>
          <span v-else>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-icon rounded-circle"
              pill
              variant="secondary"
              @click="cancelAction"
            >
              <i class="icon-0-icons-dark-cross" />
              <span class="d-none d-md-inline"> {{ $t('buttons.cancel') }} </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-icon rounded-circle ml-1"
              pill
              variant="primary"
              :style="colorPrimaryBtn"
              @click="assignCover"
            >
              <i class="icon-0-icons-dark-floppy" />
              <span class="d-none d-md-inline"> {{ $t('buttons.save') }} </span>
            </b-button>
          </span>
        </p>
        <!--/ cargar imagen -->

        <!-- avatar club -->
        <div>
          <b-avatar
            size="120px"
            :src="logoImage"
            class="bg-white i-shadow-new i-translate-avatar-cl"
            style="position: sticky"
          />
          <h3 class="pt-1 text-dark i-translate-avatar-cl">
            <strong>{{ club.club_name }}</strong>
          </h3>
        </div>
      <!--/ avatar club -->
      </b-col>
      <!--/ Imagenes del club -->

      <!-- Información del club -->
      <b-col
        v-if="checkPermission(['EDIT_CLUB', 'ALLOW_ALL'])"
        md="12"
        class="text-center text-dark i-translate-avatar-cl"
      >
        <b-list-group class="i-border-radius-cp">
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.country') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ club.country ? club.country.labelables[0].label : '' }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.state') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ club.state ? club.state.name : '' }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.corporate.sportsClubName') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ club.club_name }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.corporate.denominationOrTradeName') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ club.denomination }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.corporate.clubAdministrators') }}</strong>
              </b-col>
              <b-col
                v-if="club.users"
                md="6"
              >
                <div>
                  <span
                    v-for="(adminX, index) in club.users"
                    :key="adminX.hash"
                    class="float-left"
                  >
                    <span v-if="index != 0">,</span>
                    {{ adminX.full_name }}
                  </span>
                </div>
                <span
                  v-if="checkPermission(['EDIT_CLUB', 'ALLOW_ALL'])"
                  id="icon-edit-admin"
                  v-b-tooltip.hover.bottom="club.users.length ? $t('clubs.corporate.updateAdministrator') : $t('clubs.corporate.addAdministrator')"
                  class="float-right ml-1 i-apply-pointer"
                  @click="assignAdminUser"
                >
                  {{ club.users.length ? $t('buttons.update') : $t('buttons.add') }}
                  <i
                    v-if="club.users.length"
                    class="icon-0-icons-dark-edit"
                  />
                  <feather-icon
                    v-else
                    icon="PlusIcon"
                    size="15"
                  />
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.registrationDate') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ club.created_at | formatDate }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!--/ Información del club -->

      <!-- Botones -->
      <b-col md="12">
        <hr>
        <!--
        <b-button
          v-if="checkPermission(['DONWLOAD_CORPORATE_PDF', 'ALLOW_ALL'])"
          class="float-right ml-1"
          variant="dark"
          pill
          @click="downloadPDF"
        >
          Descargar PDF
        </b-button>
        -->
        <b-button
          v-if="checkPermission(['EDIT_CLUB', 'ALLOW_ALL'])"
          v-ripple.400="'rgba(255, 255, 255, 0.4)'"
          class="float-right ml-1"
          variant="outline-primary"
          pill
          @click="editClub"
        >
          {{ $t('buttons.edit') }}
        </b-button>
        <b-button
          v-if="checkPermission(['ALLOW_ALL'])"
          class="float-right ml-1"
          variant="secondary"
          pill
          @click="$router.push({ name: 'clubs' })"
        >
          {{ $t('buttons.back') }}
        </b-button>
      </b-col>
      <!-- Botones -->

      <!-- Actualizar administrador -->
      <assign-admin
        :users="users"
        :club="club"
        :club-id="Number($route.params.id)"
        :color-primary-btn="colorPrimaryBtn"
        @refresh-info-club="fetchClubInfo"
      />
    <!--/ Actualizar administrador -->
    </b-row>
    <b-row v-else>
      <b-col
        cols="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters } from 'vuex'
import { BRow, BCol, BAvatar, BListGroup, BListGroupItem, BButton, VBTooltip, BImg, BOverlay } from 'bootstrap-vue'
import { Cropper } from 'vue-advanced-cropper'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { storeMediaFile, destroyMediaFile } from '@/services/mediaFileService'
import checkPermission from '@/auth/permissions'
import clubService from '@/services/clubsService'
import Ripple from 'vue-ripple-directive'
import AssignAdmin from './AssignAdmin.vue'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    AssignAdmin,
    BRow,
    BCol,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BButton,
    BImg,
    Cropper,
    BOverlay,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      club: {},
      users: [],
      imageUpdated: false,
      previousImageId: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      applyOverlay: false,
      croppedImage: null,
      logoImage: null,
      image: null,
    }
  },

  mounted() {
    if (this.$route.params.id === 'undefined' && this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ name: 'clubs' })
      return
    }
    if (this.$route.params.id === 'undefined' && !this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
      this.$router.push({ path: `/clubs/${this.clubInfo.hash}/corporate` })
    }
    this.fetchClubInfo()
  },

  computed: {
    ...mapGetters({ path: 'path', colorPrimaryBtn: 'colorPrimaryBtn', clubInfo: 'clubInfo' }),
  },

  methods: {
    checkPermission,
    validatorFileSize,
    validatorFileType,

    fetchClubInfo() {
      this.applyOverlay = true
      clubService.getClubInfo(this.$route.params.id).then(({ data }) => {
        const auxUser = data.data.users
        this.club = data.data

        if (auxUser.length) {
          this.club.users = auxUser.filter(item => item.roles[0].is_admin_club)
        }

        this.club.cover_image_file = this.club.cover_image_media_file == null ? require('@/assets/images/profile/user-uploads/green.jpg') : this.path + this.club.cover_image_media_file.url_relative
        this.previousImageId = this.club.cover_image_media_file == null ? null : this.club.cover_image_media_file.hash
        this.logoImage = this.club.club_image_media_file == null ? null : this.path + this.club.club_image_media_file.url_relative
        this.applyOverlay = false
      }).catch(error => {
        this.applyOverlay = false
        this.$router.push({ name: 'error-404' })
      })
    },

    inputImageRenderer() {
      // const validSize = this.validatorFileSize(this.$refs.refInputCover.files[0])
      const validType = this.validatorFileType(this.$refs.refInputCover.files[0])

      // if (!validSize) {
      //   this.cancelAction()
      //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
      // } else

      if (!validType) {
        this.cancelAction()
        this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
      } else {
        this.getFileInBase64(this.$refs.refInputCover, base64 => {
          this.croppedImage = base64
        })
      }
    },

    getFileInBase64(pCover, callback) {
      const auxCoverImage = pCover.files[0]
      const fileReader = new FileReader()

      fileReader.addEventListener(
        'load',
        () => {
          callback(fileReader.result)
        }, false,
      )

      if (auxCoverImage) {
        fileReader.readAsDataURL(auxCoverImage)
      }
    },

    change({ coordinates, canvas }) {
      this.coordinates = coordinates
      this.image = canvas.toDataURL()
    },

    cancelAction() {
      this.croppedImage = null
      this.image = null
    },

    assignCover() {
      const auxImage = this.dataURLtoFile(this.image, `cover_${this.club.club_name}.png`)

      const formImage = new FormData()
      formImage.append('file', auxImage)
      formImage.append('folder', 'clubs')
      formImage.append('club_id', this.$route.params.id)

      storeMediaFile(formImage).then(({ data }) => {
        this.imageUpdated = true
        this.club.cover_image_file = this.path + data.data.url_relative
        this.club.cover_image_file_id = data.data.hash
        this.updateClubInfo()
      }).catch(error => { })

      this.cancelAction()
    },

    updateClubInfo() {
      const dataForm = {
        cover_image_file: this.club.cover_image_file,
        cover_image_file_id: this.club.cover_image_file_id,
      }

      clubService.updateClub(dataForm, this.$route.params.id).then(({ data }) => {
        if (this.imageUpdated) {
          this.destroyImage()
        }
        this.fetchClubInfo()
      }).catch(error => {

      })
    },

    destroyImage() {
      if (this.previousImageId != null) {
        destroyMediaFile(this.previousImageId).then(() => {

        }).catch(error => {
          this.responseCatch(error)
        })
      }
    },

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])

      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    assignAdminUser() {
      clubService.getUsersByClubId(this.$route.params.id).then(({ data }) => {
        this.users = data.data.users
        this.$bvModal.show('users-list-m')
      }).catch(error => {

      })
    },

    editClub() {
      this.$router.push({ name: 'edit-club' })
    },

    downloadPDF() {

    },
  },
}
</script>

<style scoped>
#icon-edit-admin {
  color: #33334d;
  font-size: 14px;
}

#icon-edit-admin:hover {
  color: #6abeff;
}

.i-demo-img {
  border-top-left-radius: 0.358rem;
  border-top-right-radius: 0.358rem;
  width: 100%;
  object-fit: contain;
}

.i-border-radius-cp {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.i-translate-avatar-cl {
  transform: translate(0px, -65px);
}

.i-translate-btn-upload {
  transform: translate(-50px, 0px);
}

.cropper {
  height: 312px;
  background: #DDD;
}

.i-display-elements {
  display: inline-block;
  position: relative;
}

.i-display-elements p{
  position: absolute;
  bottom: 100px;
  right: 23px;
}
</style>
